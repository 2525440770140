<template>
  <basic-modal
    :id="$props.modalId"
    ref="loginModalRef"
    size="midi"
    :cancel-label="currentStep?.actions.label?.cancel"
    :primary-label="currentStep?.actions.label?.primary"
    :primary-track-as="currentStep?.actions.trackAs?.primary"
    :secondary-label="currentStep?.actions.label?.secondary"
    :secondary-track-as="currentStep?.actions.trackAs?.secondary"
    :type="currentStep?.actions.type"
    @click-primary="currentStep?.proceed"
    @click-secondary="currentStep?.back"
    @click-tertiary="loginModalRef?.close"
    @close="onClose"
  >
    <template #title>
      {{ currentStep.titleLabel }}
    </template>

    <component
      :is="currentStep.component"
      ref="dynamicContentRef"
      v-bind="currentStep.props"
      @error="handleError"
      @submit="currentStep.submit"
      @[customEvent]="currentStep?.actions.custom?.callback"
    />
  </basic-modal>
</template>

<script setup>
import { computed, defineAsyncComponent, onBeforeUnmount, onMounted, reactive, ref, watch, watchEffect } from 'vue'

import { events$, loading$ } from '@/services'

import { ReactivityUtil } from '@/utils/Reactivity'
import { isOnVitalCampaignPageWhileLogin } from '@/utils/HaX'

import customerAPI from '@/api/customer'
import useApplication from '@/hooks/useApplication'
import useAuth from '@/hooks/useAuth'
import useContentsquare from '@/hooks/useContentsquare'
import useHn from './steps/hn/useHn'
import useI18n from '@/hooks/useI18n'

import BasicModal from '@/components/Basic/Modal'

import {
  CHANNEL,
  LOGIN_OPEN_MODAL,
  LOGIN_TYPE,
  LOGIN_STEP,
  LOGIN_STEP_CS_ID,
  LOGIN_ERROR_STATE,
} from '@/config/constants'
import { EVENT_MODAL, EVENT_LOGIN } from '@/config/events'
import { logWarning } from '@/helpers/Logger'

// HOOKS
const { getDvpUrl } = useApplication()
const { loginExistingCustomer } = useAuth()
const { triggerCSPageEvent } = useContentsquare()
const { answers, hasCardError, question, verifyAnswer, verifyCardNumber, verifyPartnerNumber } = useHn()
const { t, selectedLanguage } = useI18n()

// INIT
const props = defineProps({
  modalId: {
    type: String,
    required: true,
  },
})

// DATA
const current = ref(LOGIN_STEP.TYPE_SELECTION)
const dynamicContentRef = ref(null)
const errorType = ref(LOGIN_ERROR_STATE.GENERAL)
const isActive = ref(false)
const localBasket = reactive({})
const localChannel = ref(props.channel)
const localEmail = ref(null)
const localId = ref(null)
const localMobile = ref(null)
const loginModalRef = ref(null)
const loginType = ref(null)
const localPartnerNumber = ref(null)
const localPersons = reactive([])
const platform = ref(null)
const localUid = ref(null)
const navigateToDvp = ref(false)

// COMPUTED
const headOfContract = computed(() => {
  const contractOwnerId = localBasket?.contractOwner?.personId
  return localBasket?.persons?.find(p => p.personId === contractOwnerId)
})

const currentStep = computed(() => {
  return steps.value[current.value]
})

const customEvent = computed(() => {
  return currentStep.value?.actions.custom?.name
})

const steps = computed(() => {
  return {
    [LOGIN_STEP.TYPE_SELECTION]: {
      actions: {
        type: 'transactional',
        label: {
          primary: t('login.typeSelection.button.kupo'),
          secondary: t('login.typeSelection.button.hn'),
        },
        trackAs: {
          primary: 'login_kupo',
          secondary: 'login_hn',
        },
      },
      component: defineAsyncComponent(() => import('./steps/TypeSelection')),
      back: () => {
        // LOGIN VIA HN QUESTIONS
        loginType.value = LOGIN_TYPE.HN
        setCurrentStep(LOGIN_STEP.AUTH_HN_CUSTOMER_NUMBER)
      },
      proceed: () => {
        // LOGIN VIA KUPO
        const loginStorage = {
          componentId: localId.value,
          modalId: props.modalId,
          startingChannel: localChannel.value,
          step: LOGIN_STEP.SUCCESS,
        }

        localStorage.setItem(LOGIN_OPEN_MODAL, JSON.stringify(loginStorage))

        const redirectUri =
          localChannel.value === CHANNEL.ENTRYWIDGET ? getDvpUrl(selectedLanguage.value) : window.location.href

        loginExistingCustomer(redirectUri, selectedLanguage.value)
      },
      props: {},
      titleLabel: t('login.typeSelection.title'),
    },

    [LOGIN_STEP.AUTH_HN_CUSTOMER_NUMBER]: {
      actions: { type: 'progress' },
      back: () => {
        setCurrentStep(LOGIN_STEP.TYPE_SELECTION)
      },
      component: defineAsyncComponent(() => import('./steps/hn/CustomerNumber')),
      submit: async ({ partnerNumber }) => {
        try {
          const nextStep = await verifyPartnerNumber(partnerNumber)
          setCurrentStep(nextStep)
        } catch (error) {
          handleError(error.message)
        }
      },
      proceed: () => {
        dynamicContentRef.value.customerNumberRef.submit()
      },
      props: {},
      titleLabel: t('login.authform.hn.customerNumber.title'),
    },

    [LOGIN_STEP.AUTH_HN_CARD_NUMBER]: {
      actions: { type: 'progress' },
      back: () => {
        setCurrentStep(LOGIN_STEP.AUTH_HN_CUSTOMER_NUMBER)
      },
      component: defineAsyncComponent(() => import('./steps/hn/CardNumber')),
      submit: async ({ cardNumber }) => {
        try {
          const nextStep = await verifyCardNumber(cardNumber)
          // There was a card entry error, let the user try again
          if (!nextStep) return

          setCurrentStep(nextStep)
        } catch (error) {
          handleError(error.message)
        }
      },
      proceed: () => {
        dynamicContentRef.value.cardNumberRef.submit()
      },
      props: { showError: hasCardError.value },
      titleLabel: t('login.authform.hn.cardNumber.title'),
    },

    [LOGIN_STEP.AUTH_HN_QUESTIONS]: {
      actions: { type: 'progress' },
      back: () => {
        setCurrentStep(LOGIN_STEP.AUTH_HN_CARD_NUMBER)
      },
      component: defineAsyncComponent(() => import('./steps/hn/Question')),
      submit: async ({ answerKey }) => {
        try {
          const next = await verifyAnswer(answerKey)
          // More questions, stay on current login step
          if (next === LOGIN_STEP.AUTH_HN_QUESTIONS) return

          localPartnerNumber.value = next.data.partnerNumber
          localUid.value = next.data.uid
          ReactivityUtil.reAssign(localBasket, next.data.basket)
          setCurrentStep(next.step)
        } catch (error) {
          handleError(error.message)
        }
      },
      proceed: () => {
        dynamicContentRef.value.questionRef.submit()
      },
      props: { answers, question },
      titleLabel: t(`login.authform.hn.questions.${question.position}.title`),
    },

    [LOGIN_STEP.SUCCESS]: {
      actions: {
        type: 'transactional',
        label: {
          primary: t('form.next'),
          secondary: t('form.cancel'),
        },
      },
      back: () => {
        loginModalRef.value.close()
      },
      component: defineAsyncComponent(() => import('./steps/success/Form')),
      proceed: () => {
        dynamicContentRef.value.successFormRef.submit()
      },
      props: {
        basket: localBasket,
        loginType: loginType.value,
      },
      submit: () => {
        if (!localBasket.email) return setCurrentStep(LOGIN_STEP.EMAIL)
        if (!localBasket.mobile) return setCurrentStep(LOGIN_STEP.MOBILE)
        setCurrentStep(LOGIN_STEP.PERSONS_SELECT)
      },
      titleLabel: t('login.success.title'),
    },

    [LOGIN_STEP.EMAIL]: {
      actions: {
        type: 'transactional',
        label: {
          primary: t('form.next'),
        },
      },
      back: () => {
        loginModalRef.value.close()
      },
      component: defineAsyncComponent(() => import('./steps/success/Email')),
      proceed: () => {
        dynamicContentRef.value.emailFormRef.submit()
      },
      submit: ({ email }) => {
        localEmail.value = email
        if (!localBasket.mobile) return setCurrentStep(LOGIN_STEP.MOBILE)

        setCurrentStep(LOGIN_STEP.PERSONS_SELECT)
      },
      titleLabel: t('login.success.email.title'),
    },

    [LOGIN_STEP.MOBILE]: {
      actions: {
        type: 'transactional',
        label: {
          primary: t('form.next'),
        },
      },
      back: () => {
        loginModalRef.value.close()
      },
      component: defineAsyncComponent(() => import('./steps/success/Mobile')),
      proceed: () => {
        dynamicContentRef.value.mobileFormRef.submit()
      },
      submit: ({ mobile }) => {
        localMobile.value = mobile
        setCurrentStep(LOGIN_STEP.TAN)
      },
      titleLabel: t('login.success.mobile.title'),
    },

    [LOGIN_STEP.TAN]: {
      actions: {
        type: 'progress',
        label: {
          primary: t('form.next'),
          secondary: t('screen.reentry.resend'),
        },
      },
      back: () => {
        dynamicContentRef.value.sendSMSTan()
      },
      component: defineAsyncComponent(() => import('./steps/success/Tan')),
      proceed: () => {
        dynamicContentRef.value.tanFormRef.submit()
      },
      props: {
        basketId: localBasket.basketId,
        mobile: localMobile.value,
      },
      submit: () => {
        setCurrentStep(LOGIN_STEP.PERSONS_SELECT)
      },
      titleLabel: t('screen.reentry.resume'),
    },

    [LOGIN_STEP.PERSONS_SELECT]: {
      actions: {
        custom: {
          name: 'switch',
          callback: () => {
            setCurrentStep(LOGIN_STEP.PERSONS_NEW)
          },
        },
        type: 'transactional',
        label: {
          primary: t('form.apply'),
          secondary: t('form.cancel'),
        },
      },
      back: () => {
        loginModalRef.value.close()
      },
      component: defineAsyncComponent(() => import('./steps/persons/SelectPersons')),
      proceed: () => {
        dynamicContentRef.value.selectPersonsFormRef.submit()
      },
      props: {
        basketPersons: localBasket?.persons,
        loginType: loginType.value,
      },
      submit: persons => {
        ReactivityUtil.reAssign(localPersons, persons)
        setCurrentStep(LOGIN_STEP.LOADING)
      },
      titleLabel: t('login.person.form.title'),
    },

    [LOGIN_STEP.PERSONS_NEW]: {
      actions: {
        type: 'progress',
        label: {
          primary: t('form.apply'),
          secondary: t('form.back'),
        },
      },
      back: () => {
        setCurrentStep(LOGIN_STEP.PERSONS_SELECT)
      },
      component: defineAsyncComponent(() => import('./steps/persons/AddPersons')),
      proceed: () => {
        dynamicContentRef.value.addPersonsFormRef.submit()
      },
      props: {
        basketPersons: localBasket?.persons,
        loginType: loginType.value,
      },
      submit: addedPersons => {
        ReactivityUtil.reAssign(localPersons, addedPersons)
        setCurrentStep(LOGIN_STEP.LOADING)
      },
      titleLabel: t('login.person.addPersons.title'),
    },

    [LOGIN_STEP.LOADING]: {
      actions: {
        type: 'raw',
      },
      back: () => {},
      component: defineAsyncComponent(() => import('./steps/Loading')),
      proceed: () => {},
      props: {
        localBasket: prepareBasket(),
        loginType: loginType.value,
        partnerNumber: localPartnerNumber.value,
        platform: platform.value,
        uid: localUid.value,
      },
      submit: () => {
        setCurrentStep(LOGIN_STEP.WELCOME)
      },
      titleLabel: t('login.loading.title'),
    },

    [LOGIN_STEP.WELCOME]: {
      actions: {
        type: 'acknowledgement',
        label: {
          primary: t('form.next'),
        },
      },
      back: () => {},
      component: defineAsyncComponent(() => import('./steps/Welcome')),
      proceed: () => {
        loginModalRef.value.close()
        if (navigateToDvp.value) {
          navigateAfterSubmit()
        }
      },
      titleLabel: t('login.welcome.title', {
        firstName: headOfContract.value?.personData.firstName,
        lastName: headOfContract.value?.personData.lastName,
      }),
    },

    [LOGIN_STEP.ERROR_GENERAL]: {
      actions: {
        type: 'transactional',
        label: {
          primary: t('login.error.general.kupo.text'),
          secondary: t('form.cancel'),
        },
      },
      back: () => {
        loginModalRef.value.close()
      },
      component: defineAsyncComponent(() => import('./error/General')),
      proceed: () => {
        window.document.location.href = t('login.error.general.kupo.link')
      },
      props: {
        type: errorType.value?.toLowerCase(),
      },
      titleLabel: t(`login.error.${errorType.value?.toLowerCase()}.title`),
    },
  }
})

// METHODS
function getDataFromLocalStorage() {
  const loginStorage = localStorage.getItem(LOGIN_OPEN_MODAL)
  if (!loginStorage) return
  /*
   Remove open-login item from localStorage to only trigger modal once per login
   If there are multiple login-prompts on the screen, the componentId will make sure
   that the modal is opened from the correct prompt
   */
  let componentId, modalId, startingChannel
  try {
    ;({ componentId, modalId, startingChannel } = JSON.parse(loginStorage))
  } catch {
    logWarning('Invalid localStorage value for login, Expected object, but got: ', loginStorage)
  }

  return {
    componentId,
    modalId,
    startingChannel,
  }
}

function handleError(type) {
  errorType.value = type || LOGIN_ERROR_STATE.GENERAL
  setCurrentStep(LOGIN_STEP.ERROR_GENERAL)
}

function initModal({
  callerId,
  channel: __channel,
  modalId,
  navigateToDvp: __navigateToDvp,
  openAll,
  platform: __platform,
  step,
}) {
  if (props.modalId !== modalId && !openAll) return

  if (callerId) localId.value = callerId
  if (__channel) localChannel.value = __channel
  if (__navigateToDvp) navigateToDvp.value = __navigateToDvp

  if (step === LOGIN_STEP.SUCCESS) {
    /*
          We expect users to already be logged in at this point :/
          but we have to set the login type manually and also load the family basket
          the same way we do after manually logging in with Kupo
        */
    platform.value = __platform
    initModalAfterKupoLogin()
  } else {
    setCurrentStep(step, true)
  }

  loginModalRef.value.open()
  isActive.value = true
}

async function initModalAfterKupoLogin() {
  try {
    loading$.start()
    const passthrough = ['DVP_CAN_NOT_SUPPORT_EXISTING_CUSTOMER', 'DVP_CAN_NOT_SUPPORT_PARTNER_AS_EXISTING_CUSTOMER']
    const __basket = await customerAPI.getFamilyBasket({ platform: platform.value, passthrough })
    loginType.value = LOGIN_TYPE.KUPO
    ReactivityUtil.reAssign(localBasket, __basket)
    setCurrentStep(LOGIN_STEP.SUCCESS)
    loading$.end()
  } catch (e) {
    loading$.failed()
    // first, check, if we're on the correct campaign-page
    isOnVitalCampaignPageWhileLogin() ? handleError(LOGIN_ERROR_STATE.DVP_UNSUPPORTED) : handleError(e.message)
  } finally {
    localStorage.removeItem(LOGIN_OPEN_MODAL)
  }
}

function navigateAfterSubmit() {
  const baseUrl = getDvpUrl(selectedLanguage.value)
  window.open(baseUrl, '_self').focus()
}

function onClose() {
  current.value = LOGIN_STEP.TYPE_SELECTION
  loginType.value = null
  scrollToWidget()
  isActive.value = false
}

function prepareBasket() {
  const newBasket = { ...localBasket, channel: localChannel.value }
  newBasket.persons = localPersons
  if (localEmail.value) newBasket.email = localEmail.value
  if (localMobile.value) newBasket.mobile = localMobile.value
  // Overwrite lang with currently selected language
  newBasket.language = selectedLanguage.value.toUpperCase()
  return newBasket
}

/**
 * @note: is only used for the productWidget, as we don't scroll to the entryWidget (and is not in use in dvp)
 */
function scrollToWidget() {
  const callerEl = document.getElementById(localId.value)
  if (callerEl) {
    callerEl.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }
}

function setCurrentStep(step, isInitial = false) {
  current.value = step || LOGIN_STEP.TYPE_SELECTION
  if (!isInitial) {
    events$.emit(EVENT_MODAL.UPDATE, {
      modalName: `${props.modalId}--${current.value}`,
    })
  }
}

// WATCHER
watch(
  () => loginModalRef.value?.isOpen,
  val => {
    if (val) {
      events$.emit(EVENT_LOGIN.OPENED, localChannel.value)
    }
  }
)

watchEffect(() => {
  if (isActive.value && LOGIN_STEP_CS_ID[current.value]) triggerCSPageEvent(LOGIN_STEP_CS_ID[current.value])
})

// LIFECYCLE HOOKS
onBeforeUnmount(() => {
  events$.off(EVENT_LOGIN.OPEN, initModal)
})

onMounted(() => {
  const localStorageData = getDataFromLocalStorage()
  if (localStorageData && props.modalId === localStorageData.modalId) {
    localId.value = localStorageData.componentId
    localChannel.value = localStorageData.startingChannel

    // TODO: Added a timeout because there's no handling of multiple loading-starts
    setTimeout(async () => {
      await initModalAfterKupoLogin()
      loginModalRef.value.open()
      isActive.value = true
    }, 500)
  }

  events$.on(EVENT_LOGIN.OPEN, initModal)
})

defineExpose({ isActive })
</script>

<style scoped></style>
